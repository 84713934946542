class Page {
  static byQuerySelector(selector, args) {
    return new this({element: document.querySelector(selector), ...args});
  }

  constructor({element}) {
    this.localStorageState = `${pageID}_${this.name}_state`;
    this.element = element;
    this._prev = null;
    this._next = null;

    if (!Page.instances)
      Page.instances = [];

    Page.instances.push(this);

    window.addEventListener('storage', ({key}) => {
      if (key === this.localStorageState)
        this.render();
    });
  }

  get name() {
    throw alert('Please implement get name() -> str');
  }

  get next() {
    return this._next;
  }

  set next(page) {
    this._next = page;
    page._prev = this;
  }

  get prev() {
    return this._prev;
  }

  set prev(page) {
    this._prev = page;
    page._next = this;
  }

  setState(data) {
    this.state = data;
    this.render();
  }

  set state(data) {
    store(this.localStorageState, data);
  }

  get state() {
    let state = retrieve(this.localStorageState);

    return state !== null ? state : {};
  }

  get finalState() {
    return {
      ...(this.prev !== null ? this.prev.finalState : {}),
    }
  }

  static get first() {
    return Page.instances[0];
  }

  querySelectorAll(selector) {
    return this.element.querySelectorAll(selector);
  }

  querySelector(selector) {
    return this.element.querySelector(selector);
  }

  render() {
  }

  show() {
    Loader.instance.show();

    Page.instances
      .filter(page => page !== this)
      .forEach(page => page.hide());

    this.render();

    addClass(this.element, 'active-wrapper');
    Loader.instance.hide();

    if (window.dataLayer !== undefined) {
      dataLayer.push({
        'event': `${this.name}`,
        'page': `${this.name}`,
      });
    }
  }

  hide() {
    removeClass(this.element, 'active-wrapper');
  }
}

class BreadCrumbPage extends Page {
  constructor({index, ...args}) {
    super(args);

    if (!BreadCrumbPage.breadcrumb_instances)
      BreadCrumbPage.breadcrumb_instances = [];

    BreadCrumbPage.breadcrumb_instances.push(this);

    this.breadCrumb = null;
    this.index = index;
    this.edit = null;
    this.visible = false;

    this.renderBreadCrumb();
  };

  get breadCrumbData() {
    alert('Please implement get breadCrumbData() -> object');
  }

  render() {
    super.render();
    BreadCrumbPage.renderAllBreadcrumbs();
  }

  show() {
    this.visible = true;
    super.show();
  }

  hide() {
    this.visible = false;
    super.hide();
  }

  static get visiblePage() {
    let [currentPage] =
      BreadCrumbPage.breadcrumb_instances.filter(p => p.visible);

    return currentPage || null;
  }

  get isComplete() {
    let {visiblePage} = BreadCrumbPage;
    return visiblePage !== null
      && this.index <= visiblePage.index
      && (this.prev !== null ? this.prev.isComplete : true);
  }

  get isEditable() {
    let {visiblePage} = BreadCrumbPage;
    return this.isComplete
      && visiblePage !== null
      && this.index < visiblePage.index;
  }

  static renderAllBreadcrumbs() {
    BreadCrumbPage.breadcrumb_instances.forEach((i) => {
      i.renderBreadCrumb();
    })
  }

  renderBreadCrumb() {
    let source = Handlebars.compile(document.querySelector(".breadcrumb-template-js").innerHTML);

    let newBreadCrumb = createElement(source({
      complete: this.isComplete,
      number: this.isComplete ? null : this.index + 1,
      edit: this.isEditable,
      showSummary: this.isEditable,
      ...this.breadCrumbData,
    }));

    let breadCrumbNav = document.querySelector('.breadcrumb-nav-js');
    if (this.breadCrumb === null)
      breadCrumbNav.appendChild(newBreadCrumb);
    else
      breadCrumbNav.replaceChild(newBreadCrumb, this.breadCrumb);

    this.breadCrumb = newBreadCrumb;

    if (this.isEditable) {
      this.breadCrumb
        .querySelector('.edit-js')
        .addEventListener('click', () => this.show());
    }
  }
}

class DatePage extends BreadCrumbPage {
  constructor(...args) {
    super(...args);

    this
      .querySelector('.open-datepicker-js')
      .addEventListener('click', () => this.openDatePicker());

    addDirectEventListener(
      this.querySelector('.close-datepicker-js'),
      'click', () => this.closeDatePicker());

    this.confirmButton = this.querySelector('.confirm-js');
    this.confirmButton.addEventListener('click', () => this.closeDatePicker());

    this.loadTimeslotsForMonth(new Date());

    this.dateInput = this.querySelector('.datepicker-wrapper .datepicker');
    $(this.dateInput).datepicker({
      format: 'mm/dd/yyy',
      startDate: moment().startOf('day').toDate(),
      endDate: moment().add(1, 'M').toDate(),
      container: '.datepicker-wrapper',
      todayHighlight: true,
      immediateUpdates: true,
    }).on('changeDate', ({date}) => this.onSelectDate(date))
      .on('changeMonth', ({date}) => this.loadTimeslotsForMonth(date))
      .on('changeYear', ({date}) => this.loadTimeslotsForMonth(date));

    this.timeslotWrapper = this.querySelector('.timeslot-wrapper-js');
    this.selectAllCheckbox = this.querySelector('.select-all-wrapper-js');
    this.selectAllCheckbox.addEventListener('change', ({target}) => {
      if (this.selectAllCheckbox.getAttribute('data-disabled', 'false') === 'true') {
        target.checked = !target.checked;
      } else {
        this.timeslotWrapper
          .querySelectorAll('.checkbox-timeslot')
          .forEach(checkbox => checkbox.checked = target.checked);

        this.onSelectTimeslot();
      }
    });

    this.promoCode = this.querySelector('.promocode-js');
    this.promoCode.addEventListener('change', ({target}) => this.onPromoCode(target));

    this.counter = this.querySelector('.counter-js');
    this.counter.addEventListener('change', ({target}) => this.onCountChange(target));

    this
      .querySelector('.counter-up-js')
      .addEventListener('click', () => {
        let {count} = this.state;
        this.setState({
          ...this.state,
          count: ++count,
        })
      });

    this
      .querySelector('.counter-down-js')
      .addEventListener('click', () => {
        let {count} = this.state;
        this.setState({
          ...this.state,
          count: count > 1 ? --count : count,
        })
      });

    if (this.state.count === undefined) {
      this.state = {
        ...this.state,
        count: 1
      };
    }

    this.continueButton = this.querySelector('.continue-js');
    this.continueButton.addEventListener('click', () => this.next.show());
  }

  get name() {
    return 'DatePage';
  }

  get breadCrumbData() {
    let {selectedDate, count} = this.state;
    let ts = this.selectedTimeslots;

    let mDate = moment(selectedDate);

    return {
      name: 'Select Date',
      summary: !this.isComplete ? [] : [
        `${mDate.format('DD-MM-YYYY')} â ${moment(ts[0].start).format('HH:mm')} - ${moment(ts[ts.length - 1].end).format('HH:mm')}`,
        `${count} Guest${count > 1 ? 's' : ''}`,
      ]
    }
  }

  openDatePicker() {
    let el = this.querySelector('.backdrop');
    addClass(el, 'active-backdrop');
  }

  closeDatePicker() {
    let el = this.querySelector('.backdrop');
    removeClass(el, 'active-backdrop');
  }

  onSelectDate(date) {
    var momentDate = moment(date);
    let {
      timeslots,
      selectedTimeslots,
      selectedDate: previousSelectedDate
    } = this.state;

    if (previousSelectedDate !== undefined
      && !moment(previousSelectedDate).isSame(momentDate, 'day')) {
      selectedTimeslots = undefined;
    }

    let selectedDate = momentDate.startOf('day').toISOString();
    if (timeslots.month !== momentDate.format('M')) {
      this.loadTimeslotsForMonth(date, selectedDate);
    }

    this.setState({
      ...this.state,
      selectedTimeslots,
      selectedDate
    })
  };

  loadTimeslotsForMonth(date, selectedDate = undefined) {
    let mDate = moment(date)

    API.instance.getTimeslots(
      mDate.startOf('month').format('YYYY-MM-DDT00:00:00'),
      mDate.endOf('month').format('YYYY-MM-DDT23:59:59'),
      undefined,
      false,
    ).then((timeslots) => {
      let refTimeslots = Object
        .values(timeslots)
        .reduce((timeslots, additional_timeslots) => timeslots.concat(additional_timeslots), [])
        .reduce((timeslots, timeslot) => {
          let date = moment(timeslot.start).format('YYYY-MM-DD');
          if (!timeslots.hasOwnProperty(date)) {
            timeslots[date] = [];
          }
          timeslots[date].push({
            ...timeslot,
            start: timeslot.start,
            end: timeslot.end,
          });
          return timeslots

        }, {})

      this.setState({
        ...this.state,
        selectedDate,
        selectedTimeslots: undefined,
        timeslots: {
          ...refTimeslots,
          month: mDate.format('M')
        }
      });
    });

    return true;
  }

  onSelectTimeslot() {
    let selectedTimeslots = Array
      .from(
        this
          .timeslotWrapper
          .querySelectorAll('.checkbox-timeslot:checked')
      )
      .map(ts => ts.getAttribute('data-start'));

    this.setState({
      ...this.state,
      selectedTimeslots,
    });
  }

  renderTimeslots() {
    removeChildren(this.timeslotWrapper);

    let source = Handlebars.compile(document.querySelector(".timeslot-template-js").innerHTML);

    let {selectedTimeslots = [], timeslots = [], selectedDate} = this.state;

    let date = moment(selectedDate).format('YYYY-MM-DD');

    let timeslotsToday = timeslots.hasOwnProperty(date)
      ? timeslots[date]
      : [];

    if (timeslotsToday.length === 0)
      return this.renderTimeslotPlaceholder(true);

    this.selectAllCheckbox.setAttribute('data-disabled', false);

    timeslotsToday.forEach((timeslot) => {
      timeslot.available = moment(timeslot.start).utc().diff(moment.utc()) <= 0 ? false : timeslot.available;
      let timeslotElement = createElement(source({
        ...timeslot,
        selected: selectedTimeslots.includes(moment(timeslot.start).format('HH:mm')),
        startTime: moment(timeslot.start).format('HH:mm'),
        endTime: moment(timeslot.end).format('HH:mm'),
      }));

      timeslotElement.addEventListener('change', () => this.onSelectTimeslot());
      this.timeslotWrapper.append(timeslotElement);
    });
  }

  renderTimeslotPlaceholder(no_timeslots = false) {
    this.selectAllCheckbox.setAttribute('data-disabled', true);
    let source = Handlebars.compile(document.querySelector(".timeslot-placeholder-js").innerHTML);
    this.timeslotWrapper.innerHTML = source({
      message: no_timeslots
        ? "No timeslots have been found for this date."
        : "Select a date so you can choose your timeslot."
    });
  }

  get selectedTimeslots() {
    let {selectedDate, selectedTimeslots = [], timeslots = {}} = this.state;
    let date = moment(selectedDate).format('YYYY-MM-DD');

    return timeslots.hasOwnProperty(date)
      ? timeslots[date].filter(
        (ts) => selectedTimeslots.includes(moment(ts.start).format('HH:mm')))
      : [];
  }

  renderPreview() {
    let {selectedDate} = this.state;

    let month = this.querySelector('.date-picker-month');
    let day = this.querySelector('.date-picker-day');
    let year = this.querySelector('.date-picker-year');
    let startTime = this.querySelector('.timeslot-range-start-js');
    let endTime = this.querySelector('.timeslot-range-end-js');

    if (selectedDate !== undefined) {
      let momentDate = moment(selectedDate);
      month.innerHTML = momentDate.format('MMMM');
      day.innerHTML = momentDate.format('D');
      year.innerHTML = momentDate.format('YYYY');
    } else {
      month.innerHTML = '';
      day.innerHTML = '-';
      year.innerHTML = '';
    }

    let timeslots = this.selectedTimeslots;
    if (timeslots.length > 0) {
      startTime.innerHTML = moment(timeslots[0].start).format('HH:mm');
      endTime.innerHTML = moment(timeslots[timeslots.length - 1].end).format('HH:mm');
    } else {
      startTime.innerHTML = '';
      endTime.innerHTML = '';
    }
  }

  onPromoCode(input) {
    this.setState({
      ...this.state,
      promoCode: input.value,
    });
  }

  onCountChange(input) {
    let count = parseInt(input.value);
    if (isNaN(count) || count <= 0)
      count = this.state.count;

    this.setState({
      ...this.state,
      count
    });
  }

  render() {
    super.render();

    let {selectedDate, count = 1, promoCode = ''} = this.state;

    if (selectedDate !== undefined) {
      let momentDate = moment(selectedDate);
      $(this.dateInput).datepicker('update', momentDate.toDate());
      this.renderTimeslots();
    } else {
      $(this.dateInput).datepicker('update', null);
      this.renderTimeslotPlaceholder();
    }

    this.renderPreview();

    if (this.isComplete)
      this.continueButton.removeAttribute('disabled');
    else
      this.continueButton.setAttribute('disabled', true);

    if (this.validateTimeslots())
      this.confirmButton.removeAttribute('disabled');
    else
      this.confirmButton.setAttribute('disabled', true);

    this.counter.value = count;
    this.promoCode.value = promoCode;
  }

  get finalState() {
    let {selectedDate, count, promoCode} = this.state;
    let timeslots = this.selectedTimeslots;

    let start =
      combineDateTime(selectedDate, timeslots[0].start);
    let end =
      combineDateTime(selectedDate, timeslots[timeslots.length - 1].end);

    return {
      start, end,
      promoCode,
      quantity: count,
      nrOfTimeslots: timeslots.length
    }
  }

  get isComplete() {
    let {count} = this.state;

    return super.isComplete
      && this.validateTimeslots()
      && count >= 1;
  }

  validateTimeslots() {
    let {selectedDate, timeslots, selectedTimeslots} = this.state;

    if (selectedDate === undefined
      || selectedTimeslots === undefined
      || selectedTimeslots.length === 0)
      return false;

    let dayTimeslots = (timeslots[moment(selectedDate).format('YYYY-MM-DD')] || []).map(
      (ts) => moment(ts.start).format('HH:mm')
    );

    return selectedTimeslots
      .map((time) => dayTimeslots.indexOf(time))
      .reduce((allValid, _, index, indexes) => {
        let thisValid = true;

        if (index > 0)
          thisValid = thisValid && Math.abs(indexes[index] - indexes[index - 1]) === 1;

        if (index < indexes.length - 1)
          thisValid = thisValid && Math.abs(indexes[index] - indexes[index + 1]) === 1;

        return allValid && thisValid;
      }, true);
  }
}

class RoomPage extends BreadCrumbPage {
  get name() {
    return 'RoomPage';
  }

  get breadCrumbData() {
    let {selectedResource} = this.state;
    return {
      name: 'Choose Room',
      summary: !this.isComplete ? [] : [
        selectedResource.name,
      ]
    }
  }

  get isComplete() {
    let {selectedResource} = this.state;
    return super.isComplete
      && selectedResource !== null;
  }

  static flattenResourceTree(resources) {
    return Object
      .values(resources)
      .reduce((list, value) => [
        ...list,
        ...(
          value.hasOwnProperty('Id')
            ? [value]
            : this.flattenResourceTree(value)
        )
      ], [])
  }

  show() {
    Loader.instance.show();

    let {start, end, quantity} = this.prev.finalState;

    API.instance.findAvailableResources(
      moment(start).format("YYYY-MM-DDTHH:mm:ss.sss+01:00"),
      moment(end).format("YYYY-MM-DDTHH:mm:ss.sss+01:00"),
      quantity
    ).then((resources) => {
      this.state = {
        selectedResource: null,
        resources: RoomPage
          .flattenResourceTree(resources)
          .filter((r) => r.bookable)
      };
      super.show();
    });
  }

  onSelectRoom(resource) {
    this.setState({
      ...this.state,
      selectedResource: resource,
    })

    this.next.show();
  }

  renderResources() {
    let {resources = []} = this.state;

    let source = Handlebars.compile(
      this.querySelector('.room-template-js').innerHTML
    );

    let wrapper = this.querySelector('.rooms-js');
    removeChildren(wrapper);

    resources.forEach((resource) => {
      let element = createElement(source(resource));

      element
        .querySelector('.book-room-js')
        .addEventListener('click', () => this.onSelectRoom(resource));

      wrapper.append(element);
    });

    if (resources.length === 0) {
      source = Handlebars.compile(
        this.querySelector('.no-rooms-template-js').innerHTML
      );

      let element = createElement(source());
      wrapper.append(element);
    }

  }

  render() {
    super.render();
    this.renderResources();
  }

  get finalState() {
    let {selectedResource} = this.state;
    return {
      ...super.finalState,
      resource: selectedResource,
    }
  }
}

class DetailsPage extends BreadCrumbPage {
  constructor({...args}) {
    super({...args});

    this.submit = this.querySelector('.submit-js');
    this.submit.addEventListener('click', (e) => {
      e.preventDefault();
      this.onSubmit();
    });

    this.inputs = this.querySelectorAll('.validate-js');
    this.inputs.forEach((input) => {
      input.addEventListener('change', ({target}) => this.onInputChange(target));

      if (input.tagName === 'SELECT') {
        input.addEventListener('change', ({target}) => this.onSelectChange(target));
        input.addEventListener('input', ({target}) => this.onSelectChange(target));
      }
    });

    phoneNumberInput(this.querySelector('[name=phonenumber]'), ({target}) => this.onInputChange(target));

    Object
      .entries(this.state)
      .forEach(([name, value]) => {
        let element = this.querySelector(`[name=${name}]`);

        if (!element)
          return;

        if (element.tagName === 'SELECT')
          this.onSelectChange(element);

        if (element.type === 'checkbox')
          element.checked = value;
        else
          element.value = value;

        validateInput(element);
      });
  }

  get name() {
    return 'DetailsPage';
  }

  get breadCrumbData() {
    let {fname, lname} = this.state;

    return {
      name: 'Your Details',
      summary: !this.isComplete ? [] : [
        `${fname} ${lname}`
      ]
    }
  }

  get isComplete() {
    let inputs = this.inputs
      ? Array.from(this.inputs)
      : [];

    return inputs.reduce(
      (complete, input) => complete && validateInput(input),
      super.isComplete);
  }

  get finalState() {
    return {
      ...super.finalState,
      ...this.state,
    }
  }

  onSubmit() {
    if (!this.isComplete)
      return;

    Array
      .from(this.inputs)
      .forEach(input => this.saveInput(input));

    this.next.show();
  }

  saveInput(input) {
    let newState = {...this.state};

    newState[input.name] = input.type === 'checkbox'
      ? input.checked
      : input.value;

    this.setState(newState);
  }

  onInputChange(input) {
    validateInput(input);
    this.saveInput(input);
  }

  onSelectChange(select) {
    select.parentNode
      .querySelector('.select-label-js')
      .classList.add('--on-input')
  }

  render() {
    super.render();

    if (this.isComplete)
      this.submit.removeAttribute('disabled');
    else
      this.submit.setAttribute('disabled', true);
  }
}

class ConfirmPage extends BreadCrumbPage {
  constructor({...args}) {
    super({...args})

    this.inputs = this.querySelectorAll('.validate-js');
    this.inputs.forEach((input) => {
      input.addEventListener('change', ({target}) => this.onInputChange(target));
    });

    this.confirm = this.querySelector('.confirm-js');
    this.confirm.addEventListener('click', () => this.onConfirm());
  }

  get name() {
    return 'ConfirmPage';
  }

  get breadCrumbData() {
    return {
      name: 'Confirm',
    }
  }

  get isComplete() {
    let inputs = this.inputs
      ? Array.from(this.inputs)
      : [];

    return inputs.reduce(
      (complete, input) => complete && validateInput(input),
      super.isComplete);
  }

  get finalState() {
    let superFinalState = super.finalState;

    let {quantity, resource, promoCode, nrOfTimeslots} = superFinalState;

    let price = calculatePrice(quantity, nrOfTimeslots);
    let total = calculatePrice(quantity, nrOfTimeslots, promoCode);

    return {
      ...superFinalState,
      ...this.state,
      price,
      total,
    }
  }

  onConfirm() {
    this.next.show();
  }

  saveInput(input) {
    let newState = {...this.state};

    newState[input.name] = input.type === 'checkbox'
      ? input.checked
      : input.value;

    this.setState(newState);
  }

  onInputChange(input) {
    validateInput(input);
    this.saveInput(input);
  }

  show() {
    Object
      .entries(this.state)
      .forEach(([name, value]) => {
        let element = this.querySelector(`[name=${name}]`);

        if (!element)
          return;

        if (element.tagName === 'SELECT')
          this.onSelectChange(element);

        if (element.type === 'checkbox')
          element.checked = value;
        else
          element.value = value;

        validateInput(element);
      });

    super.show();
  }

  renderSummary() {
    let {start, end, price, total} = this.finalState;

    let source = Handlebars.compile(this.querySelector('.summary-template-js').innerHTML);
    let discount = (price - total);

    this.querySelector('.summary-js').innerHTML = source({
      ...this.finalState,
      date: start.format('DD-MM-YYYY'),
      startTime: start.format('HH:mm'),
      endTime: end.format('HH:mm'),
      discount: discount > 0 ? discount.toFixed(2) : false,
      price: price.toFixed(2),
      total: total.toFixed(2),
    })
  }

  render() {
    super.render();
    this.renderSummary();

    if (this.isComplete) {
      this.confirm.removeAttribute('disabled');
    } else {
      this.confirm.setAttribute('disabled', true);
    }
  }
}

class PaymentPage extends Page {
  get name() {
    return 'PaymentPage';
  }

  get finalState() {
    return {
      ...super.finalState,
      ...this.state,
    }
  }

  createReservation() {
    let {
      resource, fname, lname, email1, phonenumber, start, end, promoCode,
      quantity, company, city, zip, country, alternativeEmail, total,
      receive_newsletters: receiveNewsletters,
    } = this.finalState;
    let {Id} = resource;

    let timeOffset = moment().tz("Europe/Amsterdam").format('Z');
    let mStart = `${moment(start).format("YYYY-MM-DDTHH:mm:ss.sss")}${timeOffset}`;
    let mEnd = `${moment(end).format("YYYY-MM-DDTHH:mm:ss.sss")}${timeOffset}`;
    let price = parseFloat(total);

    return API.instance.createReservation(
      Id, null, fname, lname, email1, phonenumber,
      mStart, mEnd, null, {
        Zoku_Discount_Code__c: promoCode,
        B25__Quantity__c: quantity,
        Zoku_Company__c: company,
        Zoku_City__c: city,
        Zoku_Zip__c: zip,
        Zoku_Country__c: country,
        Zoku_Alternative_Mail__c: alternativeEmail,
        Zoku_Price__c: price,
        Zoku_Newsletter__c: receiveNewsletters,
        // Package__c: `4 Hours`,
        B25__Title__c: `${moment(start).format('YYYYMMDD')} - ${company} - ${quantity} PAX`
      }
    ).then(({reservation, error}) => {
      this.setState({reservation});

      if (error)
        return Promise.reject({message: error});

      return Promise.resolve({reservation});
    });
  }

  createPayment() {
    let {total, reservation} = this.finalState;
    let {Id} = reservation;

    return API.instance
      .createPayment(Id, total)
      .then(({url, error}) => {
        if (error)
          return Promise.reject({message: error});

        window.location.replace(url);

        return Promise.resolve()
      });
  }

  show() {
    super.show();

    Loader.instance.show();

    let {total} = this.finalState;

    this
      .createReservation()
      .then(() => {
        if (total === 0)
          return Promise.resolve();
        return this.createPayment();
      })
      .then(() => {
        this.next.show({success: true});
      })
      .catch(({message}) => {
        console.error('Reservation failed: ', message);
        this.next.show({success: false, error: message});
      });
  }
}

class PaymentCallbackPage extends Page {
  get name() {
    return 'PaymentCallbackPage';
  }

  confirmBooking() {
    let {reservation} = this.finalState;
    let {Id} = reservation;

    return API.instance.updateReservation(Id, {
      custom_fields: {'Zoku_Booking_Engine_Completed__c': true}
    });
  }

  show({success, error}) {
    super.show();

    Loader.instance.show();

    const source = Handlebars.compile(this.querySelector('.payment-callback-template-js').innerHTML);
    const promise = success
      ? this.confirmBooking()
      : Promise.reject({success: false, error});

    promise.then(() => {
      this.querySelector('.payment-callback-js').innerHTML = source({success: true});
    }).catch(({error}) => {
      this.querySelector('.payment-callback-js').innerHTML = source({success: false});
      console.error(error);
    }).finally(() => {
      // localStorage.clear();
      Loader.instance.hide();
    });
  }
}
